<template>
  <div class="animated fadeIn container">
    <h1>
      원하는 발송량만큼
      <br />
      충전해 주세요
    </h1>
    <h2 class="color-skyblue">건당 {{ untxedSmsFee }}원/ 한글, 영문 70자까지 입력</h2>
    <div class="row button-container mt-4">
      <div class="col-md-2 col-sm-2">
        충전금액
      </div>
      <div class="col-md-4 col-sm-3">
        <!-- <input type="text" v-model="money" /> -->
        <span style="font-size:20px">{{ money | numberFormat }}</span>
        <span class="ml-1 ">원</span>
      </div>
      <div class="col col-sm-6 text-center">
        <ul class="button-list">
          <li
            :key="prefixMoney"
            class="btn-primary"
            v-for="prefixMoney in prefixMoneys"
            @click.prevent="money += prefixMoney"
          >
            <span class="d-md-down-none">+</span> {{ prefixMoney | numberFormat }}
          </li>
          <li class="btn-secondary" @click.prevent="money = 0">
            <span style="color:black">초기화</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2 col-sm-2"></div>
      <div class="col-md-8 col-sm-7">
        <span class="color-skyblue">
          <span class="font-weight-bold">{{ availableSendCount | numberFormat }}</span
          >건 발송가능</span
        >
        <span class="color-flatwhite"> | 부가세 10% 포함 결제금액 {{ taxedMoney | numberFormat }}원</span>
      </div>
    </div>

    <p>
      <a href="/payment/history" class="history-link color-flatwhite">충전 내역 바로가기</a>
    </p>
    <b-btn variant="primary" class="mt-4 payment-btn" @click.prevent="clickPayment()">충전하기</b-btn>
    <p class="color-flatwhite" style="margin-top:100px">
      * 충전은 10분 안에 자동으로 처리됩니다.<br />
      * 입금자명은 바뀌어도 무관하며 금액만 맞으면 처리됩니다.<br />
      * 24시간 충전가능합니다. (은행 점검 시간 제외)
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PaymentService from '@/services/PaymentService'
import moment from 'moment'

export default {
  name: 'paymentStep1',
  data() {
    return {
      untxedSmsFee: 0,
      money: 0,
      prefixMoneys: [10000, 50000, 100000, 300000, 500000, 1000000, 2000000],
      paymentResult: null,
    }
  },
  mounted() {
    var IMP = window.IMP // 생략가능
    IMP.init('imp17932233')
    this.getUserInfo()
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  watch: {
    user() {
      this.untxedSmsFee = (parseFloat(this.user.smsFee) / 1.1).toFixed(0)
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    availableSendCount() {
      return parseInt(parseFloat(this.money) / this.untxedSmsFee)
    },
    taxedMoney() {
      return parseInt(parseFloat(this.money) * 1.1)
    },
  },
  methods: {
    async createPayment(result) {
      const response = await PaymentService.create(result)
      if (response.status != 200) {
        return
      }

      if (!response.data.payment) {
        alert('요청 실패')
      }

      this.$router.push('/payment/vbank/' + response.data.payment.id)
    },
    clickPayment() {
      if (this.money < 10000) {
        alert('만원 이상의 금액을 입력해주세요')
        return
      }

      window.IMP.request_pay(
        {
          pg: 'html5_inicis',
          pay_method: 'vbank',
          merchant_uid: 'merchant_' + new Date().getTime(),
          name: '헬로문자',
          amount: this.taxedMoney,
          buyer_email: this.user.email,
          buyer_name: '문자충전-' + this.user.username,
          buyer_tel: '',
          buyer_addr: '',
          buyer_postcode: '',
          vbank_due: moment()
            .add(1, 'days')
            .format('YYYYMMDD'),
        },
        successResult => {
          //성공할 때 실행 될 콜백 함수
          console.log(successResult)
          if (successResult.success) {
            this.createPayment({
              imp_uid: successResult.imp_uid,
              merchant_uid: successResult.merchant_uid,
            })
          }
        },
        failureResult => {
          //실패시 실행 될 콜백 함수
          var msg = '결제에 실패하였습니다.'
          msg += '에러내용 : ' + failureResult.error_msg
          alert(msg)
        }
      )
    },
  },
}
</script>

<style scoped>
.button-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.button-container > div {
  display: flex;
  align-items: center;
}

.button-list {
  list-style: none;
  width: 100%;
  line-height: 20px;
}

.button-list > li {
  float: left;
  color: white;
  position: relative;
  width: 23%;
  margin-left: 2%;
  padding: 4px 0;
  margin: 3px 1%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.5px;
  height: 28px;
  overflow: auto;
  min-width: 70px;
}

.payment-btn {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
}

.history-link {
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}

.history-link:hover {
  color: #333;
}
</style>
